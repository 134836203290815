import {Injectable, NgZone} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {FCM} from "@capacitor-community/fcm";
import {Router, Params} from '@angular/router';
import {NotifyModel} from '../model/notify-model';
import {switchMap, map, tap, toArray} from 'rxjs/operators';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications';

import {Subject, ReplaySubject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {DatabaseService} from '../services2/database.service';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {CoreService} from './core.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BadgeService} from './badge.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public static typeNotifications = 'notifications';  // Уведомления
    public static typePush = 'push';                    // Токены для отправки Push

    notifications = 'notifications';

    private countBadge: ReplaySubject<any>;
    login: string;
    badge: number;

    constructor(
        private db: AngularFireDatabase,
        private router: Router,
        private plt: Platform,
        private translateService: TranslateService,
        private databaseService: DatabaseService,
        private zone: NgZone,
        private afMessaging: AngularFireMessaging,
        private coreService: CoreService,
        private firestore: AngularFirestore,
        private badgeService: BadgeService
    ) {
        this.countBadge = new ReplaySubject(1);
    }

    initPush(login: string) {
        this.login = login;

        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                PushNotifications.register();
            } else {
            }
        });

        PushNotifications.addListener('registration',
            async (token: Token) => {
                // Get FCM token instead the APN one returned by Capacitor
                if (this.plt.is('ios')) {
                    const {token: fcm_token} = await FCM.getToken();
                    token.value = fcm_token;
                }
                this.saveTokenPush(token.value);
                console.log('PUSH_TOKEN:', token.value);
                // alert('Push registration success, token: ' + token.value);
            }
        );

        this.firestore.collection('contractor').doc(this.login).get().subscribe(res => {
            let subscriptions = res.data()['subscriptions'];

            subscriptions.forEach((sub) => {
                FCM.subscribeTo({topic: this.transliterate(sub)})
                    .then((r) => console.log('subscribed to topic'))
                    .catch((err) => console.log(err));
            });
        });

        // const subscriptions$ = this.databaseService.listReferenceFull('typeEvent')
        // .pipe(
        //   switchMap(categoryItems =>
        //     this.listSubscriptionsInclude()
        //       .pipe(
        //         map(subscriptionItems => {
        //             categoryItems.sort((a, b) => (a.sort > b.sort) ? 1 : -1);
        //             return categoryItems.map(item => ({
        //                 on: subscriptionItems[item.name],
        //                 ...item
        //               })
        //             );
        //           }
        //         ),
        //       )
        //   )
        // );
        // subscriptions$.subscribe(res => {
        //   console.log(res);
        // });


        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                console.log('Push received: ' + JSON.stringify(notification));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                console.log('Push action performed: ' + JSON.stringify(notification));
                let data = notification.notification.data;
                if (data.url) {
                    this.navigate({type: data.type, url: data.url});
                }
            }
        );


        this.afMessaging.requestPermission
            .subscribe(
                () => {
                    // alert('Notification permission granted');
                    console.log('Notification permission granted!');
                },
                (error) => {
                    // alert('Notifications error');
                    console.error('Notification permission not granted!', error);
                },
            );

        this.afMessaging.requestToken
            .subscribe(
                (token) => {
                    console.log('afPUSH_TOKEN:', token);
                    this.saveTokenPush(token);
                },
                (error) => {
                    // alert('error');
                    console.error(error);
                },
            );

        this.afMessaging.messages
            .subscribe((message) => {
                console.log('afMESSAGE: ', message);
            });

        this.afMessaging.onMessage(data => {
            console.log('afOnMessageReceived');
            console.log(JSON.stringify(data));

            console.log(data);
            this.coreService.presentToast('Новое уведомление');

            if (data.tap === 'background') {
                this.navigate({type: data.type, url: data.url});
            } else {
                this.coreService.presentToast('Новое уведомление');
            }
        });

        // this.afMessaging.setBadgeNumber(0);
    }

    // Сохранение токена для отправки Push-уведомлений
    saveTokenPush(token: string) {
        // console.log('Токен для пушей сохранен');
        this.databaseService.saveBlock(`users/${this.login}/push`, this.login, {
            token
        });
    }

    // Сохранение токена для отправки Push-уведомлений
    saveTokenToTopic(token: string) {
        console.log(this.login);
        this.databaseService.saveBlock(`users/${this.login}/push`, this.login, {
            token
        });
    }

    // Запись уведомления пользователя
    updateNotify(category: string, userID: string, notify: NotifyModel): Promise<void> {
        return new Promise((resolve, reject) => {
            this.translateService.get(notify.subject).subscribe(async (res: string) => {
                notify.subject = res;

                console.log('updateNotify', `${category}_${notify.user}`);
                this.db.object(`${this.notifications}/${userID}/${category}_${notify.user}`)
                    .update(notify)
                    .then(() => {
                        resolve();
                    });
            });
        });
    }

    list() {
        return this.db
            .list(`${this.notifications}/${this.login}`
                // , ref => ref.orderByChild('subject').equalTo('Новое сообщение')
                , ref => ref.orderByChild('subject').equalTo('Новое предложение')
            )
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map((a: any) => {
                        const data = a.payload.val() as NotifyModel;
                        data['key'] = a.payload.key;
                        return data;
                    })
                )
            );
    }

    // Подписка на количество непрочитанных уведомлений
    getBadge(): Subject<number> {
        return this.countBadge;
    }

    setBadge(badge: number) {
        console.log("setBadge", badge);
        this.countBadge.next(badge);
    }

    // Отметка о прочтении уведомления
    setMarkRead(key: string) {
        const subscription = this.db.object(`${this.notifications}/${this.login}/${key}`)
            .valueChanges().subscribe(value => {
                subscription.unsubscribe();

                if (value) {
                    if ((value as NotifyModel).subject) {
                        this.db.object(`${this.notifications}/${this.login}/${key}`)
                            .update({
                                isRead: true
                            });
                    }
                }
            });
    }

    // инициализация системы уведомлений
    initNotify() {
    }

    navigate(item: Params) {
        console.log('navigate');
        console.log(JSON.stringify(item));
        let url;
        if (item.type === 'Новое сообщение') {
            url = '/chat/' + item.url;
        } else if (item.type === 'Заказчик назначил на выполнение мероприятия') {
            url = '/detail/' + item.url;
        } else if (item.type === 'Назначено личное задание') {
            url = '/detail/' + item.url;
        } else if (item.type === 'Новый отклик') {
            url = '/detail/' + item.url;
        }

        if (url) {
            this.zone.run(() => {
                this.router.navigateByUrl(url);
            });
        }
    }

    transliterate(word: string): string {
        if (!word) {
            return word;
        }

        let answer = '';
        const a: { [key: string]: string } = {};

        const wordLower = word.toLowerCase();

        a["ё"] = "yo";
        a["й"] = "i";
        a["ц"] = "ts";
        a["у"] = "u";
        a["к"] = "k";
        a["е"] = "e";
        a["н"] = "n";
        a["г"] = "g";
        a["ш"] = "sh";
        a["щ"] = "sch";
        a["з"] = "z";
        a["х"] = "h";
        a["ф"] = "f";
        a["ы"] = "i";
        a["в"] = "v";
        a["а"] = "a";
        a["п"] = "p";
        a["р"] = "r";
        a["о"] = "o";
        a["л"] = "l";
        a["д"] = "d";
        a["ж"] = "zh";
        a["э"] = "e";
        a["я"] = "ya";
        a["ч"] = "ch";
        a["с"] = "s";
        a["м"] = "m";
        a["и"] = "i";
        a["т"] = "t";
        a["ь"] = "";
        a["б"] = "b";
        a["ю"] = "yu";
        a[" "] = "_";
        a["0"] = "0";
        a["1"] = "1";
        a["2"] = "2";
        a["3"] = "3";
        a["4"] = "4";
        a["5"] = "5";
        a["6"] = "6";
        a["7"] = "7";
        a["8"] = "8";
        a["9"] = "9";

        for (let i = 0; i <= wordLower.length; i++) {
            if (a[wordLower[i]]) {
                answer += a[wordLower[i]];
            }
        }
        return answer.toLowerCase();
    }
}
