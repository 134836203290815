import {Injectable, NgZone} from '@angular/core';
import firebase from 'firebase/compat/app';
import {Observable, ReplaySubject} from 'rxjs';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {HttpClient} from '@angular/common/http';
import {CoreService} from './core.service';
import {FIREBASE_CONFIG} from '../../firebase.credentials';
import {first, map, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {DatabaseService} from '../services2/database.service';
import {NotificationService} from './notification.service';
import {Platform} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public state: ReplaySubject<firebase.User> = new ReplaySubject(1);
    login: string;
    phone: string;
    name: string;
    isExistProfileUser: boolean;
    isExistRequiredFields: boolean;
    balance: number;
    type: number;
    oldType: number;
    role$: ReplaySubject<{ type: number }>;

    constructor(
        private db: AngularFireDatabase,
        private platform: Platform,
        private httpClient: HttpClient,
        private coreService: CoreService,
        private router: Router,
        private zone: NgZone,
        private firestore: AngularFirestore,
        private databaseService: DatabaseService,
        private notificationService: NotificationService,
    ) {
        this.role$ = new ReplaySubject(1);

        firebase.auth().onAuthStateChanged(authData => {
            if (authData != null) {
                console.log('onAuthStateChanged', authData.uid);
                this.databaseService.login = authData.uid;
                this.login = authData.uid;
                if (authData.phoneNumber != null) {
                    this.phone = authData.phoneNumber;
                }

                this.state.next(authData);
                this.getInfoUser();
                if (this.platform.is('ios') || this.platform.is('android')) {
                    this.notificationService.initPush(this.login);
                }
            } else {
                // firebase.auth().signInAnonymously();

                this.role$.next({type: -1});

                this.login = null;
                this.phone = null;
                this.state.next(null);
            }
        }, x => console.log(x));

    }

    getInfoUser() {
        this.databaseService.detailItemBlock('users', this.login).subscribe(item => {
            this.isExistProfileUser = !!item?.fio;
            this.balance = item?.balance || 0;
            this.type = item?.type;
            this.role$.next({type: this.type});
            this.name = item?.fio;
        });
    }

    async getCurrentUser() {
        return await new Promise<any>((resolve, reject) => {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    resolve(user);
                } else {
                    reject('No user logged in');
                }
            }, x => console.log(x));
        });
    }

    guardAuth(): Observable<boolean> {
        return this.state.asObservable()
            .pipe(
                map(item => !!item),
                tap(item => {
                    if (!item) {
                        this.zone.run(() => {
                            this.router.navigate(['/auth']);
                        });
                    }
                })
            );
    }


    isAdmin() {
        return this.db.object('setting/admin').valueChanges().pipe(
            map(user => {
                return this.getLogin() === user;
            })
        );
    }

    // авторизация пользователя
    // возвращает в промис логин пользователя
    async auth() {
        return await new Promise((resolve, reject) => {
            this.state.subscribe(authData => {
                resolve(authData.uid);
            });
        });
    }

    // Сохраненный логин авторизованного пользователя
    getLogin(): string {
        return firebase.auth().currentUser.uid;
    }

    async getUser() {
        return await this.databaseService.detailItemBlock('users', this.login).pipe(first()).toPromise();
    }

    // Присоединение номера телефона к аккаунту
    linkAuthPhone(phone: string) {
        if (!phone) {
            this.coreService.presentToast('Введите номер телефона');
            return;
        }

        if (phone.substr(0, 1) === '+') {
            phone = phone.substr(1);
        }

        const server = `https://us-central1-${FIREBASE_CONFIG.projectId}.cloudfunctions.net`;
        const uid = firebase.auth().currentUser.uid;

        this.httpClient.get(`${server}/getAuthID?phone=${phone}&uid=${uid}`).subscribe(data => {
            if (data['error']) {
                this.coreService.presentToast(data['error']);
                return;
            }

            document.location.href = data['call'];
            this.db.object(`authHistory/${data['key']}/call_id`).valueChanges().subscribe(value => {
                if (value) {
                    firebase.auth().signInWithCustomToken(value as string);
                }
            });
        });
    }

    // выход из учетной записи
    logout() {
        firebase.auth().signOut();
    }

    // пользовательское соглашение
    agreement() {
        return this.firestore.collection('setting').doc('agreement').valueChanges()
            .pipe(
                map(item => item['text'])
            );
    }
}
