import {Badge, BadgePlugin} from "@capawesome/capacitor-badge";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class BadgeService {
    async get() {
        const result = await Badge.get();
        return result.count;
    };

    async set(count: number) {
        await Badge.set({count});
    };

    async increase() {
        await Badge.increase();
    };

    async decrease() {
        await Badge.decrease();
    };

    async clear() {
        await Badge.clear();
    };

    async isSupported() {
        const result = await Badge.isSupported();
        return result.isSupported;
    };

    async checkPermissions() {
        const result = await Badge.checkPermissions();
    };

    async requestPermissions() {
        const result = await Badge.requestPermissions();
    };
}