import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference, QueryFn} from '@angular/fire/compat/firestore';
import {Params} from '@angular/router';
import {map, switchMap, shareReplay, tap, first} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {CoreService} from '../services/core.service';

@Injectable({
    providedIn: 'root'
})
export class DatabaseService {

    login: string; // текущий логин
    cacheDetail: { [key: string]: Observable<Params> };
    object_id: BehaviorSubject<string> = new BehaviorSubject<string>('');
    peopleLengthList = 30; // длина списка для подгрузки списка подрядчиков

    constructor(
        private firestore: AngularFirestore,
        private coreService: CoreService,
    ) {
        this.cacheDetail = {};
    }

    // ### Работа с CRUD блоками ###
    saveBlockDetail(ref: DocumentReference, item: Params) {
        return ref.collection('detail').doc('0').set(item, {merge: true});
    }

    // добавление
    addBlock(type: string, itemHeader: Params, item?: Params): Promise<any> {
        return this.firestore.collection(type).add({
            ...itemHeader,
            user: this.login
        }).then((saveData: any) => {
                const path = saveData.path.split('/');
                this.object_id.next(path[1]);
                item ? this.saveBlockDetail(saveData, item) : Promise.resolve();
                return this.object_id.getValue();
            }
        );
    }

// firecheck.ru/api/objects.php?type=get&floors=5
    // Сохранение ранее загруженного
    saveBlock(type: string, id: string, itemHeader: Params, item?: Params): Promise<any> {
        const blockedType = {
            contractor: 'Пользователь заблокирован',
            users: 'Пользователь заблокирован',
            company: 'Компания заблокирована',
            object: 'Объект заблокирован',
            mapobject: 'Объект заблокирован'
        };

        if (blockedType[type] && itemHeader.blocked) {
            this.coreService.presentToast(blockedType[type]);
            return Promise.reject();
        }

        const blockRef = this.firestore.collection(type).doc(id).ref;
        return blockRef.set({...itemHeader}, {merge: true}).then(() => {
            item ? this.saveBlockDetail(blockRef, item) : Promise.resolve();
            return id;
        });
    }

    // список
    listBlock(type: string, queryFn?: QueryFn): Observable<Params[]> {
        return this.firestore.collection(type, queryFn)
            .snapshotChanges()
            .pipe(
                map(items =>
                    items.map((item: any) =>
                        ({id: item.payload.doc.id, ...item.payload.doc.data() as Params})
                    )
                )
            );
    }

    // удаление
    deleteBlock(type: string, id: string) {
        return this.firestore.collection(type).doc(id).delete();
    }

    // детали дополнительно
    detailBlock(type: string, id: string): Observable<Params> {
        return this.firestore.collection(type).doc(id)
            .collection('detail').doc('0')
            .valueChanges();
    }

    // детали в списке
    detailItemBlock(type: string, id: string) {
        if (!this.cacheDetail[`${type}_${id}`]) {
            this.cacheDetail[`${type}_${id}`] = this.firestore.collection(type).doc(id)
                .valueChanges()
                .pipe(
                    shareReplay(1)
                );
        }

        return this.cacheDetail[`${type}_${id}`];
    }

    // детали в списке
    detailItemBlockNoCache(type: string, id: string) {
        return this.firestore.collection(type).doc(id)
            .valueChanges();
    }

    // детали в строке и дополнительно
    detailFullBlock(type: string, id: string) {
        return this.detailItemBlock(type, id)
            .pipe(
                switchMap(item => this.detailBlock(type, id)
                    .pipe(
                        map(itemDetail => ({...itemDetail, ...item}))
                    ))
            );
    }


    // справочник
    listReference(type: string): Observable<string[]> {
        return this.firestore.collection('setting').doc('reference')
            .collection(type, ref => ref.orderBy('sort', 'asc'))
            .valueChanges()
            .pipe(
                map(items =>
                    items.map(item =>
                        item['name']
                    )
                )
            );
    }

    // справочник
    listReferenceFull(type: string): Observable<Params[]> {
        return this.firestore.collection('setting').doc('reference')
            .collection(type, ref => ref.orderBy('name', 'asc'))
            .snapshotChanges()
            .pipe(
                map(items =>
                    items.map((item: any) =>
                        ({
                            id: item.payload.doc.id,
                            ...item.payload.doc.data()
                        })
                    )
                )
            );
    }

    // справочник
    listReferenceFullPromise(type: string) {
        return this.firestore.collection('setting').doc('reference')
            .collection(type, ref => ref.orderBy('name', 'asc'))
            .valueChanges()
            .pipe(first()).toPromise();
    }
}
